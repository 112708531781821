import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Gallery,
  Heading,
  type MediaTileList,
  type MediaTileListItem,
  Span,
} from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { Stack } from "components/common/stack";
import { type GalleryData } from "components/home-services/inspections";
import { AuthAppContext } from "components/root/auth-app-provider";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";
import { CONFIG } from "config";
import { type Assets, type Referral } from "gql/__generated__/hooks";
import { checkForNaStringValue, downloadImage } from "utils";

import { IneligibleWriteOutEntry } from "./ineligible-write-out-entry";
import styles from "./inspections-ineligible.module.scss";

export const InspectionsIneligible = () => {
  const navigate = useNavigate();
  const [galleryData, setGalleryData] = useState<GalleryData | null>(null);
  const { selectedPolicy } = useContext(AuthAppContext);

  useEffect(() => {
    const statusEnum = selectedPolicy?.inspection?.statusEnum;
    if (statusEnum && statusEnum !== "480") {
      navigate("/my/overview");
    }
  }, [navigate, selectedPolicy?.inspection?.statusEnum]);

  if (!selectedPolicy?.inspection?.statusEnum) {
    return <BaseLayout loading />;
  }

  const referralList =
    selectedPolicy?.inspection?.newBusinessInspectionWriteOuts?.referralList ??
    [];
  const sortedReferralList = referralList.toSorted((referral) => {
    return referral?.referralCategory === "ROOF" ? -1 : 1;
  });

  const ineligibleReferrals: Referral[] = [];
  const otherReferrals: Referral[] = [];
  (sortedReferralList ?? []).forEach((referral) => {
    if (referral?.referralActionRequired === "Risk is ineligible") {
      ineligibleReferrals.push(referral);
    } else if (referral) {
      otherReferrals.push(referral);
    }
  });

  const shownAssets = galleryData?.assets;
  if (shownAssets && shownAssets.length > 0) {
    const media: MediaTileList & { fileName: string }[] = shownAssets.map(
      (asset, idx) => {
        const guid = asset.url?.split("/").pop();
        return {
          fileName: asset?.fileName ?? `image_${guid}`,
          id: idx.toString(),
          source: `${CONFIG.KEYSTONE_PROXY_HREF}/api/inspection/image/${guid}`,
        };
      }
    );

    return (
      <Gallery
        media={media}
        title={galleryData.title}
        subheading={galleryData.subHeading}
        primaryAction="Close"
        onPrimaryAction={() => setGalleryData(null)}
        onClose={() => setGalleryData(null)}
        activeAction="Download"
        onActiveAction={(active: MediaTileListItem & { fileName: string }) => {
          downloadImage(active.source, active.fileName);
        }}
      />
    );
  }

  return (
    <BaseLayout>
      <MainLayout
        topRow={
          <Heading className={styles.mainHeadingDS} size="lg">
            Our inspection findings
          </Heading>
        }
        sidebar={<InsuranceRepresentative border />}
      >
        <Card border className={styles.writeOutsCardDS}>
          <Heading size="md" className={styles.headingDS}>
            Your property is ineligible for this policy
          </Heading>
          <Span>
            We may have other carriers that work for your property. Please
            contact your insurance rep to explore your options.
          </Span>
          {ineligibleReferrals.length > 0 ? (
            <Stack gap="xl" className={styles.writeOuts}>
              {ineligibleReferrals.map((referral, idx) => {
                const galleryTitle =
                  checkForNaStringValue(
                    referral?.underwritingCondition?.referralText ?? ""
                  ) ?? "";
                const gallerySubHeading =
                  checkForNaStringValue(referral?.location ?? "") ?? "";
                return (
                  <IneligibleWriteOutEntry
                    key={idx}
                    referral={referral}
                    primaryIneligible={true}
                    showGallery={() => {
                      if (referral && referral?.assets) {
                        setGalleryData({
                          assets: referral.assets as Assets[],
                          title: galleryTitle,
                          subHeading: gallerySubHeading,
                        });
                      }
                    }}
                  />
                );
              })}
            </Stack>
          ) : null}
        </Card>

        {otherReferrals.length > 0 ? (
          <Card border className={styles.writeOutsCardDS}>
            <Heading size="md" className={styles.headingDS}>
              Other concerns found
            </Heading>
            <Span>
              We noticed a few extra issues during our inspection. These
              won&apos;t affect your policy eligibility.
            </Span>
            <Stack gap="xl" className={styles.writeOuts}>
              {otherReferrals.map((referral, idx) => {
                const galleryTitle =
                  checkForNaStringValue(
                    referral?.underwritingCondition?.referralText ?? ""
                  ) ?? "";
                const gallerySubHeading =
                  checkForNaStringValue(referral?.location ?? "") ?? "";
                return (
                  <IneligibleWriteOutEntry
                    key={idx}
                    referral={referral}
                    primaryIneligible={false}
                    showGallery={() => {
                      if (referral && referral?.assets) {
                        setGalleryData({
                          assets: referral.assets as Assets[],
                          title: galleryTitle,
                          subHeading: gallerySubHeading,
                        });
                      }
                    }}
                  />
                );
              })}
            </Stack>
          </Card>
        ) : null}
      </MainLayout>
    </BaseLayout>
  );
};
