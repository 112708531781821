import { Button, Heading, Span } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import { WriteOutIcon } from "components/home-services/write-out-icon";
import { type Referral } from "gql/__generated__/hooks";
import { checkForNaStringValue, trackEvent } from "utils";

import styles from "./ineligible-write-out-entry.module.scss";

export const IneligibleWriteOutEntry = ({
  referral,
  primaryIneligible,
  showGallery,
}: {
  referral: Referral;
  primaryIneligible: boolean;
  showGallery: () => void;
}) => {
  const { assets, location, underwritingCondition, referralCategory } =
    referral;

  const writeOutHeading = checkForNaStringValue(
    underwritingCondition?.referralText ?? ""
  );
  const reasoning = checkForNaStringValue(
    underwritingCondition?.reasoning ?? ""
  );
  const locationDisplay = checkForNaStringValue(location ?? "");

  return (
    <div className={styles.writeOut}>
      <div
        className={
          primaryIneligible
            ? styles.ineligibleWriteOutIcon
            : styles.writeOutIcon
        }
      >
        <WriteOutIcon category={referralCategory} />
      </div>
      <Stack gap="sm">
        {writeOutHeading ? (
          <Heading size="sm">{writeOutHeading}</Heading>
        ) : null}
        {reasoning ? (
          <Span color="quiet" size="sm">
            {underwritingCondition?.reasoning}
          </Span>
        ) : null}

        <div className={styles.twoEntryLine}>
          {locationDisplay ? (
            <div>
              <Span bold>Location: </Span>
              <Span>{locationDisplay}</Span>
            </div>
          ) : null}
          {assets && assets.length > 0 ? (
            <Span bold>
              <Button
                size="xs"
                leadingIcon="FileJpg"
                appearance="link"
                data-testid="ineligible-write-out-photos-button"
                onPress={() => {
                  trackEvent(
                    "Inspection ineligible write-outs: Photo gallery opened",
                    {
                      numberOfPhotos: assets.length,
                      referral,
                    }
                  );
                  showGallery();
                }}
              >
                {assets.length} photo{assets.length > 1 ? "s" : ""}
              </Button>
            </Span>
          ) : null}
        </div>
      </Stack>
    </div>
  );
};
