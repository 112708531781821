import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Card,
  Gallery,
  Heading,
  type MediaTileList,
  type MediaTileListItem,
  Span,
} from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";
import { ThumbtackModalProvider } from "components/thumbtack-modal";
import { CONFIG } from "config";
import { type Assets, type Referral } from "gql/__generated__/hooks";
import { InspectionsIneligible } from "pages/inspections-ineligible";
import { checkForNaStringValue, downloadImage, formatDate } from "utils";

import styles from "./inspections.module.scss";
import { WriteOutEntry } from "./write-out-entry";

export type GalleryData = {
  assets: Assets[];
  title: string;
  subHeading: string;
};

export const Inspections = () => {
  const [galleryData, setGalleryData] = useState<GalleryData | null>(null);
  const navigate = useNavigate();
  const { selectedPolicy, isPolicyDataReady } = useContext(AuthAppContext);
  const { dueDate } =
    selectedPolicy?.inspection?.newBusinessInspectionWriteOuts ?? {};
  const referralList: (Referral | null)[] =
    selectedPolicy?.inspection?.newBusinessInspectionWriteOuts?.referralList ??
    [];
  const sortedReferralList = referralList.toSorted((referral) => {
    return referral?.referralCategory === "ROOF" ? -1 : 1;
  });
  const statusEnum = selectedPolicy?.inspection?.statusEnum;
  const isIneligible = statusEnum === "480";
  const noWriteOuts = statusEnum === "200" || statusEnum === "1000";

  useEffect(() => {
    if (noWriteOuts) {
      navigate("/my/overview");
    }
  }, [navigate, noWriteOuts]);

  if (!isPolicyDataReady) {
    return <BaseLayout loading />;
  }

  if (isIneligible) {
    return <InspectionsIneligible />;
  }

  const shownAssets = galleryData?.assets;
  if (shownAssets && shownAssets.length > 0) {
    const media: MediaTileList & { fileName: string }[] = shownAssets.map(
      (asset, idx) => {
        const guid = asset.url?.split("/").pop();
        return {
          fileName: asset?.fileName ?? `image_${guid}`,
          id: idx.toString(),
          source: `${CONFIG.KEYSTONE_PROXY_HREF}/api/inspection/image/${guid}`,
        };
      }
    );

    return (
      <Gallery
        media={media}
        title={galleryData.title}
        subheading={galleryData.subHeading}
        primaryAction="Close"
        onPrimaryAction={() => setGalleryData(null)}
        onClose={() => setGalleryData(null)}
        activeAction="Download"
        onActiveAction={(active: MediaTileListItem & { fileName: string }) => {
          downloadImage(active.source, active.fileName);
        }}
      />
    );
  }

  return (
    <ThumbtackModalProvider>
      <BaseLayout>
        <MainLayout
          topRow={
            <Stack gap="md" className={styles.topRow}>
              <Heading size="lg">Our inspection findings</Heading>
              <Alert
                appearance="warning"
                description={
                  <>
                    Please make these fixes by{" "}
                    <Span bold>{formatDate(dueDate, "MMM DD, YYYY")}</Span> and
                    send proof to your insurance rep. We&apos;ll take a look and
                    confirm everything within a week.
                  </>
                }
                title=""
              />
            </Stack>
          }
          sidebar={<InsuranceRepresentative border />}
        >
          <Card className={styles.headingCardDS} border>
            <Heading size="md">
              {`Action needed on ${sortedReferralList.length} item${
                sortedReferralList.length > 1 ? "s" : ""
              }`}
            </Heading>
            <Stack gap="xl" className={styles.writeOuts}>
              {sortedReferralList.map((referral, idx) => {
                const galleryTitle =
                  checkForNaStringValue(
                    referral?.underwritingCondition?.referralText ?? ""
                  ) ?? "";
                const gallerySubHeading =
                  checkForNaStringValue(referral?.location ?? "") ?? "";
                return (
                  <WriteOutEntry
                    referral={referral ?? {}}
                    showGallery={() => {
                      if (referral && referral?.assets) {
                        setGalleryData({
                          assets: referral.assets as Assets[],
                          title: galleryTitle,
                          subHeading: gallerySubHeading,
                        });
                      }
                    }}
                    key={idx}
                  />
                );
              })}
            </Stack>
          </Card>
        </MainLayout>
      </BaseLayout>
    </ThumbtackModalProvider>
  );
};
